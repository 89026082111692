import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import firebase from "firebase";
import Loading from "components/Loading";
import { MyTextInput } from "components/forms/FormComponents";
import { useHistory, useParams } from "react-router-dom";
import { Company } from "types";
import { emptyCompany } from "helpers/fakeData";
import BackButton from "components/BackButton";

interface IParams {
  companyid: string;
}
const CompanyForm = () => {
  const [initialValues, setInitialValues] = useState<Company>(emptyCompany);
  const history = useHistory();
  const { companyid } = useParams<IParams>();
  const [isLoading, setIsLoading] = useState(true);
  var temp = history.location.pathname.split("/");
  const editing = !(temp[temp.length - 1] === "add");

  useEffect(() => {
    (async () => {
      const snapshot = await firebase.firestore().collection("companies").get();
      if (snapshot.empty) {
        console.log("No matching documents.");
        return;
      }

      if (editing) {
        const companySnapshot = await firebase
          .firestore()
          .collection("companies")
          .doc(companyid)
          .get();
        if (!companySnapshot.exists) {
          console.log("No matching documents.");

          return;
        }
        const data = companySnapshot.data() as Company;

        setInitialValues({
          ...data,
          id: companySnapshot.id,
        });
      }
    })();

    setIsLoading(false);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <BackButton />
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(40, "Must be 40 characters or less")
                .required("Required"),

              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              phonenumber: Yup.string(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const { name, email, phonenumber, id } = values;

              if (editing) {
                await firebase
                  .firestore()
                  .collection("companies")
                  .doc(companyid)
                  .update({
                    name,

                    email,
                    phonenumber,
                  });
                if (name !== initialValues.name && initialValues.employees) {
                  let promises: Promise<void>[] = [];
                  initialValues.employees.forEach((ownerId: string) => {
                    let promise = firebase
                      .firestore()
                      .collection("owners")
                      .doc(ownerId)
                      .update({
                        company: { id: companyid, name: name },
                      });
                    promises = [...promises, promise];
                  });
                  await Promise.all(promises);
                }
                setSubmitting(false);

                history.replace(`/admin/companies/${companyid}`);
              } else {
                if (id === "" || id === undefined) {
                  const company = await firebase
                    .firestore()
                    .collection("companies")
                    .add({
                      name,
                      email,
                      phonenumber,
                    });
                  history.replace(`/admin/companies/${company.id}`);
                } else {
                  await firebase
                    .firestore()
                    .collection("companies")
                    .doc(id)
                    .set({
                      name,
                      email,
                      phonenumber,
                    });
                  history.replace(`/admin/companies/${id}`);
                }

                setSubmitting(false);
              }
            }}
          >
            <Form className="grid grid-cols-1 space-y-2">
              <MyTextInput
                label="Id (si besoin, sinon ne pas remplir)"
                name="id"
                type="text"
              />
              <MyTextInput label="Nom" name="name" type="text" />

              <MyTextInput label="Adresse email" name="email" type="email" />
              <MyTextInput
                label="Téléphone de contact"
                name="phonenumber"
                type="text"
              />

              <button type="submit">{editing ? "Modifier" : "Créer"}</button>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};

export default CompanyForm;
