import { useState, useEffect, useMemo } from "react";
import firebase from "firebase";
import CustomTable from "components/UserTable";
import Loading from "components/Loading";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import { createAdmin } from "helpers/functionsHelper";

interface CompanyRow {
  id: string;
  name: any;
  nbemployees: any;
  address: any;
}

interface IHandleCreateAdmin {
  email: string;
  companyid: string;
  index: number;
}

function Companies() {
  const functions = firebase.app().functions("europe-west1");

  const history = useHistory();
  const handleNavigationToAddCompany = () => {
    history.push("companies/add");
  };
  const columns = useMemo(() => {
    const handleCreateAdmin = async ({
      email,
      companyid,
      index,
    }: IHandleCreateAdmin) => {
      setRows((c) =>
        c.map((val, idx) =>
          idx === index ? { ...val, company: "En cours" } : val
        )
      );
      try {
        const result = await createAdmin({ email, companyid });
        setRows((c) =>
          c.map((val, idx) =>
            idx === index ? { ...val, company: "Oui" } : val
          )
        );
        return result;
      } catch (error) {
        console.log(error);
        setRows((c) =>
          c.map((val, idx) =>
            idx === index ? { ...val, company: "error" } : val
          )
        );
        return;
      }
    };

    return [
      {
        Header: "Nom", // optional string or valid JSX
        accessor: "name", // required(at least for beginners) => row["firstname"]
        maxWidth: 140,
        minWidth: 100,
      },
      {
        Header: "Effectif",
        accessor: "nbemployees",
        // Use our custom `fuzzyText` filter on this column
        filter: "fuzzyText",
        maxWidth: 140,
        minWidth: 100,
      },
      {
        Header: "Email",
        accessor: "email",
        maxWidth: 150,
        minWidth: 100,
      },
      {
        Header: "Accès admin",
        accessor: "company",
        Cell: ({ cell }: any) => {
          const email = cell.row.original.email;
          const companyid = cell.row.original.id;
          const index = cell.row.index;

          return cell.value === "Non" ? (
            <button
              onClick={() => handleCreateAdmin({ email, companyid, index })}
              className="btn btn-primary  btn-sm"
            >
              Donner l'accès
            </button>
          ) : (
            <div>{cell.value}</div>
          );
        },
        maxWidth: 150,
        minWidth: 100,
      },
      {
        Header: "ID",
        accessor: "id",
      },
    ];
  }, [functions]);

  const db = firebase.firestore();

  const [rows, setRows] = useState<CompanyRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const queryTest = async () => {
      const snapshot = await db.collection("companies").get();

      if (snapshot.empty) {
        console.log("No matching documents.");
        return;
      }

      snapshot.forEach((doc) => {
        const { name, employees, address, email } = doc.data();
        const newRow = {
          id: doc.id,
          name,
          nbemployees: employees ? employees.length : 0,
          address,
          email,
          company:
            doc.data().admin && doc.data().admin.length !== 0 ? "Oui" : "Non",
        };

        setRows((arr) => [...arr, newRow]);
      });

      setIsLoading(false);
    };
    queryTest();
    //eslint-disable-next-line
  }, []);

  const handleNavigation = (companyid: string) => {
    history.push(`companies/${companyid}`);
  };

  return (
    <div>
      <h1 className="text-2xl text-center m-2 font-bold">Entreprises</h1>
      <div className="m-2">
        <Button onClick={handleNavigationToAddCompany} title="+ entreprise" />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <CustomTable
          columns={columns}
          data={rows}
          handleNavigation={handleNavigation}
        />
      )}
    </div>
  );
}

export default Companies;
