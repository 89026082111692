import { useEffect, useState } from "react";
import firebase from "firebase";
import Loading from "components/Loading";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { MyTextInput } from "components/forms/FormComponents";
import vCards from "vcards-js";
import ImageUploading from "react-images-uploading";
import AutocompletePlaces from "components/AutocompletePlaces";
import { CropImage } from "components/CropImage";
import imageCompression from "browser-image-compression";
import { emptyCard } from "helpers/fakeData";
import { socialNetworks } from "helpers/socialNetworks";
import { cardValidationSchema } from "components/forms/schemas/EditProfileValidationSchema";

function CardForm() {
  const [images, setImages] = useState([]);

  const [initialValues, setInitialValues] = useState(emptyCard);
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [owner, setOwner] = useState(null);
  const [base64Image, setBase64Image] = useState();
  const [croppedImage, setCroppedImage] = useState({
    blobUrl: "",
    base64: "",
    type: "",
  });
  const history = useHistory();
  const params = useParams();

  const [imgUrl, setImgUrl] = useState(null);
  var temp = history.location.pathname.split("/");
  const editing = !(temp[temp.length - 1] === "add");

  useEffect(() => {
    (async () => {
      const ownerSnapshot = await firebase
        .firestore()
        .collection("owners")
        .doc(params.ownerid)
        .get();

      if (ownerSnapshot.empty) {
        console.log("No matching documents.");
        return;
      }

      const { firstname, lastname, phone, job, companyName, email, website } =
        ownerSnapshot.data();

      setOwner({ id: params.ownerid, firstname, lastname });
      setInitialValues((c) => ({
        ...c,
        firstname,
        lastname,
        prophone: phone,
        proemail: email,
        job,
        company: companyName,
        website,
      }));
      if (editing) {
        const cardSnapshot = await firebase
          .firestore()
          .collection("cards")
          .doc(params.cardid)
          .get();
        if (cardSnapshot.empty) {
          console.log("No matching documents.");

          return;
        }
        const cs = cardSnapshot.data();

        const imgRef = firebase
          .storage()
          .ref()
          .child(`${params.ownerid}/${params.cardid}.${cs.imgType}`);

        const url = await imgRef.getDownloadURL();

        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
        xhr.addEventListener("load", async () => {
          var reader = new FileReader();
          const compressedImage = await handleImageUpload(xhr.response);

          reader.readAsDataURL(compressedImage);
          reader.addEventListener("loadend", function () {
            const results = reader.result.split(";base64,");
            const type = results[0].slice(5);
            const base64 = results[1];

            setBase64Image({ type: type, base64: base64 });
          });
        });

        setImgUrl(url);
        setInitialValues({
          ...cs,
          id: params.cardid,
        });
      }

      setIsLoading(false);
    })();
    //eslint-disable-next-line
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  async function handleImageUpload(imageFile) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmitPro = (result) => {
    // Parsing
    const address = {};
    result.address_components.forEach((elmt) => {
      switch (elmt.types[0]) {
        case "street_number":
          address.street_number = elmt.long_name;
          break;
        case "route":
          address.route = elmt.long_name;
          break;
        case "locality":
          address.city = elmt.long_name;
          break;
        case "country":
          address.country = elmt.long_name;
          break;
        case "postal_code":
          address.postalcode = elmt.long_name;
          break;
        case "premise":
          address.label = elmt.long_name;
          break;
        default:
          return;
      }
    });
    setInitialValues({
      ...initialValues,
      proaddress: {
        label: address.label ? address.label : "",
        street:
          (address.street_number ? address?.street_number + " " : "") +
          address.route,
        city: address.city,
        postalcode: address.postalcode,
        countryregion: address.country,
      },
    });
  };

  const handleSubmitPerso = (result) => {
    // Parsing
    const address = {};
    result.address_components.forEach((elmt) => {
      switch (elmt.types[0]) {
        case "street_number":
          address.street_number = elmt.long_name;
          break;
        case "route":
          address.route = elmt.long_name;
          break;
        case "locality":
          address.city = elmt.long_name;
          break;
        case "country":
          address.country = elmt.long_name;
          break;
        case "postal_code":
          address.postalcode = elmt.long_name;
          break;
        case "premise":
          address.label = elmt.long_name;
          break;
        default:
          return;
      }
    });
    setInitialValues({
      ...initialValues,
      otheraddress: {
        label: address.label ? address.label : "",
        street:
          (address.street_number ? address?.street_number + " " : "") +
          address.route,
        city: address.city,
        postalcode: address.postalcode,
        countryregion: address.country,
      },
    });
  };
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleGoBack}
          >
            Retour
          </button>
          {imgUrl ? <img src={imgUrl} alt="logo" width={300} /> : null}
          <ImageUploading
            value={images}
            onChange={onChange}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,

              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button onClick={() => onImageUpdate(index)}>
                        Update
                      </button>
                      <button onClick={() => onImageRemove(index)}>
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
          {images[0] ? (
            <CropImage
              imageUrl={images[0] ? images[0].data_url : null}
              setCroppedImage={setCroppedImage}
            />
          ) : null}

          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={cardValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              if (!editing && croppedImage.blobUrl === "") {
                alert("Photo Crop et enregistre stp");
                return;
              }
              if (
                editing &&
                images[0] !== undefined &&
                croppedImage.blobUrl === ""
              ) {
                alert("Crop et enregistre stp");
                return;
              }
              const db = firebase.firestore();
              let type = "jpg";
              if (editing && images.length === 0) {
                type = initialValues.imgType;
              }

              var ID = (function () {
                // Math.random should be unique because of its seeding algorithm.
                // Convert it to base 36 (numbers + letters), and grab the first 9 characters
                // after the decimal.
                return "_" + Math.random().toString(36).substr(2, 5);
              })();
              let cardId;
              editing ? (cardId = initialValues.id) : (cardId = owner.id + ID);

              const storageRef = firebase.storage().ref();
              const imageRef = storageRef.child(
                `${owner.id}/${cardId}.${type}`
              );

              if (editing) {
                if (images.length === 0) {
                  console.log("image wasnt changed");
                } else {
                  let blob = await fetch(croppedImage.blobUrl)
                    .then((r) => r.blob())
                    .then(
                      (blobFile) =>
                        new File([blobFile], "Hey", {
                          type: "image/jpeg",
                        })
                    );

                  await imageRef.put(blob);
                }
              } else {
                let blob = await fetch(croppedImage.blobUrl)
                  .then((r) => r.blob())
                  .then(
                    (blobFile) =>
                      new File([blobFile], "Hey", {
                        type: "image/jpeg",
                      })
                  );

                await imageRef.put(blob);
              }

              // Création de la vCard

              const handleVCardCreation = async (values) => {
                const {
                  firstname,
                  lastname,
                  company,
                  job,
                  prophone,
                  personalphone,
                  officephone,
                  proemail,
                  personalemail,

                  proaddress,

                  otheraddress,

                  mainwebsite,
                  secondwebsite,
                } = values;
                var vCard = vCards();
                // image
                if (editing) {
                  if (images[0]) {
                    const { base64, type } = croppedImage;
                    vCard.photo.embedFromString(base64, type);
                  } else {
                    vCard.photo.embedFromString(
                      base64Image.base64,
                      base64Image.type
                    );
                  }
                } else {
                  const { base64, type } = croppedImage;
                  vCard.photo.embedFromString(base64, type);
                }
                //set basic properties shown before
                vCard.firstName = firstname;
                vCard.lastName = lastname;
                vCard.organization = company;

                vCard.title = job;
                vCard.url = secondwebsite;
                vCard.workUrl = mainwebsite;
                vCard.note = "";

                // homephone = Domicile | workphone = Bureau | cellPhone = Mobile
                vCard.homePhone = officephone;
                vCard.workPhone = prophone;
                vCard.cellPhone = personalphone;

                vCard.email = personalemail;
                vCard.workEmail = proemail;

                vCard.workAddress = proaddress;

                vCard.homeAddress = otheraddress;

                vCard.version = "3.0";

                return vCard.getFormattedString();
              };

              const vCard = await handleVCardCreation(values);
              var vCardFile = new Blob([vCard], {
                type: "text/vcard;charset=utf-8",
              });
              // Upload to firebase storage

              const vcfRef = storageRef.child(`${owner.id}/${cardId}.vcf`);
              const cvRef = storageRef.child(`${owner.id}/${cardId}.pdf`);
              const metadata = { contentType: "text/x-vcard" };
              await vcfRef.put(vCardFile, metadata);
              if (values?.iscv && values?.cv !== undefined) {
                await cvRef.put(values.cv, "application/pdf");
              }
              if (editing && !values.iscv && initialValues.iscv) {
                await cvRef.delete();
              }

              const imageDownloadUrl = await imageRef.getDownloadURL();
              const vcfDownloadUrl = await vcfRef.getDownloadURL();
              console.log(values);
              await db
                .collection("cards")
                .doc(cardId)
                .set(
                  {
                    ...values,
                    active: true,
                    imageUrl: imageDownloadUrl,
                    vcfUrl: vcfDownloadUrl,
                    website: {
                      active: !(values.mainwebsite === ""),
                      url: values.mainwebsite,
                    },
                    templateid: "skyGrey",

                    imgType: type,
                    owner,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    edited: firebase.firestore.FieldValue.serverTimestamp(),
                  },
                  { merge: true }
                );

              await db
                .collection("owners")
                .doc(owner.id)
                .update({
                  active: cardId,
                  cards: firebase.firestore.FieldValue.arrayUnion(cardId),
                });

              setSubmitting(false);
              history.replace(`/admin/owners/${owner.id}`);
            }}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 space-y-3">
                    <h1 className="font-bold text-2xl">
                      Info carte de visite digitale
                    </h1>

                    <MyTextInput
                      label="Prénom"
                      name="firstname"
                      type="text"
                      placeholder="Jane"
                    />
                    <MyTextInput
                      label="Nom"
                      name="lastname"
                      type="text"
                      placeholder="Doe"
                    />
                    <MyTextInput
                      label="Entreprise (optionnel)"
                      name="company"
                      type="text"
                      placeholder="JustOneCard"
                    />
                    <MyTextInput
                      label="Poste"
                      name="job"
                      type="text"
                      placeholder="CEO"
                    />
                    <h1 className="font-bold text-2xl">Info contact vcf</h1>
                    <MyTextInput
                      label="Tel Bureau (+33)"
                      name="prophone"
                      type="text"
                      placeholder="(+33)612345678"
                    />
                    <MyTextInput
                      label="Tel Portable (+33)"
                      name="personalphone"
                      type="text"
                      placeholder="(+33)612345678"
                    />
                    <MyTextInput
                      label="Tel Domicile (+33)"
                      name="officephone"
                      type="text"
                      placeholder="(+33)112345678"
                    />
                    <MyTextInput
                      label="Email Pro"
                      name="proemail"
                      type="text"
                      placeholder="william@justonecard.fr"
                    />
                    <MyTextInput
                      label="Email Perso"
                      name="personalemail"
                      type="text"
                      placeholder="william@gmail.com"
                    />
                    <div className="grid row-auto">
                      <h1 className="font-bold text-lg">Adresse pro (opt)</h1>
                      <AutocompletePlaces handleSubmit={handleSubmitPro} />
                      <MyTextInput
                        label="Titre adresse pro"
                        name="proaddress.label"
                        type="text"
                        placeholder="Agence Nancy"
                      />
                      <MyTextInput
                        label="Adresse"
                        name="proaddress.street"
                        type="text"
                        placeholder="94 Rue du sergent Blandan"
                      />
                      <MyTextInput
                        label="Ville"
                        name="proaddress.city"
                        type="text"
                        placeholder="Nancy"
                      />
                      <MyTextInput
                        label="Code Postal"
                        name="proaddress.postalcode"
                        type="text"
                        placeholder="54000"
                      />
                      <MyTextInput
                        label="Pays"
                        name="proaddress.countryregion"
                        type="text"
                        placeholder="France"
                      />
                    </div>
                    <div className="grid row-auto">
                      <h1 className="font-bold text-lg">
                        Deuxième adresse (opt)
                      </h1>
                      <AutocompletePlaces handleSubmit={handleSubmitPerso} />
                      <MyTextInput
                        label="Titre adresse 2"
                        name="otheraddress.label"
                        type="text"
                        placeholder="Agence Nancy"
                      />
                      <MyTextInput
                        label="Adresse"
                        name="otheraddress.street"
                        type="text"
                        placeholder="94 Rue du sergent Blandan"
                      />
                      <MyTextInput
                        label="Ville"
                        name="otheraddress.city"
                        type="text"
                        placeholder="Nancy"
                      />
                      <MyTextInput
                        label="Code Postal"
                        name="otheraddress.postalcode"
                        type="text"
                        placeholder="54000"
                      />
                      <MyTextInput
                        label="Pays"
                        name="otheraddress.countryregion"
                        type="text"
                        placeholder="France"
                      />
                    </div>

                    <MyTextInput
                      label="Site internet"
                      name="mainwebsite"
                      type="text"
                      placeholder="https://www.justonecard.fr"
                    />
                    <MyTextInput
                      label="Second site internet"
                      name="secondwebsite"
                      type="text"
                      placeholder="https://app.justonecard.fr"
                    />

                    <h1 className="font-bold text-2xl">Info réseaux sociaux</h1>

                    {socialNetworks.map(({ title, name, placeholder }) => {
                      return (
                        <>
                          <h2>{title}</h2>

                          <MyTextInput
                            id={name}
                            name={name}
                            type="text"
                            placeholder={placeholder}
                          />
                        </>
                      );
                    })}

                    {values?.iscv ? (
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue("cv", undefined);
                          setFieldValue("iscv", false);
                        }}
                      >
                        Retirer le CV
                      </button>
                    ) : (
                      <input
                        name="cv"
                        type="file"
                        accept=".pdf, application/pdf"
                        onChange={(event) => {
                          setFieldValue("cv", event.currentTarget.files[0]);
                          setFieldValue("iscv", true);
                        }}
                      />
                    )}

                    <button type="submit">Submit</button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
}

export default CardForm;
