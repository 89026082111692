import { useState, useEffect, useMemo } from "react";
import firebase from "firebase";
import CustomTable from "components/UserTable";
import Loading from "components/Loading";
import { useHistory } from "react-router-dom";
interface OwnerRow {
  id: string;
  firstname: string;
  lastname: string;

  company: string;
  companyid: string;
  active: boolean | string;
}
function Owners() {
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: "Prénom", // optional string or valid JSX
        accessor: "firstname", // required(at least for beginners) => row["firstname"]
        maxWidth: 140,
        minWidth: 100,
      },
      {
        Header: "Nom",
        accessor: "lastname",
        // Use our custom `fuzzyText` filter on this column
        filter: "fuzzyText",
        maxWidth: 140,
        minWidth: 100,
      },

      {
        Header: "Société",
        accessor: "company",
        maxWidth: 160,
        minWidth: 100,
        filter: "fuzzyText",
      },
      {
        Header: "Url",
        accessor: "id",
        maxWidth: 150,
        minWidth: 100,
      },
    ],
    []
  );

  const db = firebase.firestore();

  const [ownersRows, setOwnersRows] = useState<OwnerRow[]>([]);
  const [owners, setOwners] = useState<OwnerRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const queryTest = async () => {
      const snapshot = await db.collection("owners").get();

      if (snapshot.empty) {
        console.log("No matching documents.");
        return;
      }

      snapshot.forEach((doc) => {
        const { firstname, lastname, company } = doc.data();
        const newOwner = {
          id: doc.id,
          firstname,
          lastname,

          company: company.name,
          companyid: company.id,
          active: doc.data()?.active ? doc.data()?.active : false,
        };
        // newOwners = [...newOwners, newOwner];
        setOwners((arr) => [...arr, newOwner]);
        setOwnersRows((arr) => [...arr, newOwner]);
      });

      setIsLoading(false);
    };
    queryTest();
    //eslint-disable-next-line
  }, []);

  const handleNavigation = (ownerid: string) => {
    history.push(`owners/${ownerid}`);
  };

  const handleDisplayUnactiveCards = () => {
    setOwnersRows(owners.filter((row) => !row.active));
  };
  const handleDisplayActiveCards = () => {
    setOwnersRows(owners.filter((row) => row.active));
  };
  const handleDisplayCards = () => {
    setOwnersRows(owners);
  };

  return (
    <div>
      <p className="text-2xl text-center m-2 font-bold">Utilisateurs</p>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-row">
            <button onClick={handleDisplayCards} className="button">
              Tous{" "}
            </button>
            <button onClick={handleDisplayActiveCards} className="button">
              Active{" "}
            </button>
            <button onClick={handleDisplayUnactiveCards} className="button">
              Non Active{" "}
            </button>
          </div>
          <CustomTable
            columns={columns}
            data={ownersRows}
            handleNavigation={handleNavigation}
          />
        </>
      )}
    </div>
  );
}

export default Owners;
