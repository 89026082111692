type ButtonProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
};
function Button({ title, onClick, disabled = false, ...props }: ButtonProps) {
  return (
    <button
      className={"btn btn-primary "}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {title}
    </button>
  );
}

export default Button;
