import joc from "assets/joc_icon.svg";
import brand from "assets/brand.svg";

function BrandBanner() {
  return (
    <div className="brand-banner">
      <img className="brand-banner__logo" src={joc} alt="joc logo" />
      <img src={brand} alt="joc name" className="" />
    </div>
  );
}

export default BrandBanner;
