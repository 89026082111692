import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import { AuthContext } from "AuthProvider";

const Login = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const [verificationCode, setVerificationCode] = useState("");
  const [verificationID, setVerificationID] = useState();
  const [resolverMFA, setResolverMFA] = useState();
  // Create an event handler so you can call the verification on button click event or form submit

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    if (
      values.email.trim() !== "william@justonecard.fr" &&
      values.email.trim() !== "antoine@justonecard.fr" &&
      values.email.trim() !== "william.bellity1@gmail.com" &&
      values.email.trim() !== "mathis@justonecard.fr" &&
      values.email.trim() !== "louise@justonecard.fr"
    ) {
      alert("Not an admin");
      return;
    }
    event.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then((res) => {
        authContext.setUser(res);
        history.push("/home");
      })
      .catch(async (error) => {
        if (error.code === "auth/multi-factor-auth-required") {
          console.log("multi factor needed");
          await handleMFA(error);
        } else {
          alert(error);
        }
      });
  };

  const handleMFA = async (error) => {
    const resolver = error.resolver;
    setResolverMFA(resolver);

    const phoneOpts = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          console.log("captcha solved!");
        },
      }
    );

    try {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneOpts,
        recaptchaVerifier
      );
      setVerificationID(verificationId);

      alert(`Un SMS a été envoyé au ${phoneOpts.multiFactorHint.phoneNumber}`);
    } catch (e) {}
  };

  const loginWithMFA = async () => {
    const cred = new firebase.auth.PhoneAuthProvider.credential(
      verificationID,
      verificationCode
    );
    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    const credential = await resolverMFA.resolveSignIn(multiFactorAssertion);

    authContext.setUser(credential.user);
    history.push("/home");
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="email"
          value={values.email}
          placeholder="Enter your Email"
          onChange={handleChange}
        />
        <br />
        <br />
        <input
          type="password"
          name="password"
          value={values.password}
          placeholder="Enter your Password"
          onChange={handleChange}
        />
        <br />
        <br />
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Connexion
        </button>
      </form>
      <div>
        <input
          id="recaptcha-container"
          name="recaptcha-container"
          placeholder="Enter your sms code"
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <button
          onClick={loginWithMFA}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Verification
        </button>
      </div>
    </div>
  );
};
export default Login;
