import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading";
import BusinessCard from "components/cards/BusinessCard";
import { QRCodeSVG } from "qrcode.react";
import BackButton from "components/BackButton";
import Button from "components/Button";
import { Card, Owner } from "types";
import leaves from "../../../assets/leavesblack.png";
import {
  deleteOwner,
  deleteQrcode,
  linkQrcodeToOwner,
} from "helpers/functionsHelper";
import toast from "react-hot-toast";

interface IParams {
  ownerid: string;
}

function OwnerInfo() {
  const [owner, setOwner] = useState<Owner>({
    firstname: "",
    lastname: "",

    company: { id: "", name: "" },
    qrcode: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cards, setCards] = useState<Card[]>([]);
  const { ownerid } = useParams<IParams>();
  const history = useHistory();

  const [qrlink, setQrlink] = useState<string[]>([]);
  const [tempQrlink, setTempQrlink] = useState<string>("");

  const handleNavigationToAddCard = () => {
    history.push(`${ownerid}/add`);
  };
  useEffect(() => {
    const getDataFromDB = async () => {
      const snapshot = await firebase
        .firestore()
        .collection("owners")
        .doc(ownerid)
        .get();

      if (!snapshot.exists) {
        console.log("no matched document");
        return;
      }
      const data = snapshot.data() as Owner;
      const { firstname, lastname, company } = data;

      let qrcode: string[] = [""];
      if (data?.qrcode !== undefined) {
        if (typeof data.qrcode === "string") {
          qrcode = [data.qrcode];
        } else {
          qrcode = data.qrcode;
        }

        setQrlink(qrcode);
      }

      setOwner({
        firstname,
        lastname,

        company,
        qrcode,
        taps: data?.taps ? data.taps : 0,
      });
      const snapshotCard = await firebase
        .firestore()
        .collection("cards")
        .where("owner.id", "==", ownerid)
        .get();

      if (snapshotCard.empty) {
        console.log("no matching elements");
        setIsLoading(false);
        return;
      }

      snapshotCard.forEach(async (doc) => {
        const data = doc.data() as Card;

        const newCard = { id: doc.id, ...data };
        setCards((arr) => [...arr, newCard]);
      });

      setIsLoading(false);
    };
    getDataFromDB();
  }, [ownerid]);

  const handleNavigateToCardInfo = (card: Card) => {
    history.push(`${ownerid}/${card.id}`, card);
  };

  const handleModify = () => {
    history.push(`/admin/owners/${ownerid}/edit`);
  };
  const handleDelete = async () => {
    const result = window.confirm(
      "Supprimer l'utilisateur ? \n Attention, la suppression est définitive ainsi que tous ses profils"
    );
    if (result) {
      setIsLoading(true);
      await deleteOwner(ownerid);
      setIsLoading(false);
      history.replace(`/admin/owners`);
    }
  };

  const addQrcode = async (qr: string) => {
    toast
      .promise(linkQrcodeToOwner(qr, ownerid), {
        loading: "Ajout du QRCode en cours",
        success: "QRCode lié ",
        error: "Erreur lors de l'ajout du QRCode (peut-être déjà utilisé)",
      })
      .then(() => {
        setQrlink((c) => [...c, qr]);
        setTempQrlink("");
      });
  };

  const handleDeleteQrcode = async (qr: string) => {
    setQrlink((c) => c.filter((elmt) => elmt !== qr));
    await toast.promise(deleteQrcode(qr, ownerid), {
      loading: "Suppression du QRCode en cours",
      success:
        "QRCode supprimé, plus personne ne peut créer de profil avec ce QRCode",
      error: "Erreur lors de la suppression du QRCode",
    });
  };

  const mediumQrcodeRef = useRef<HTMLDivElement>(null);

  function downloadBlob(blob: Blob, filename: string) {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
  }
  const downloadSVGContent = (svgRef: any) => {
    const content = svgRef.current.firstChild.innerHTML;

    const contentWithSvg = `<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" shape-rendering="crispEdges" height="128" width="128" viewBox="0 0 33 33">



${content.slice(80, -1)}>


		
</svg>`;
    const blob = new Blob([contentWithSvg], { type: "image/svg+xml" });
    downloadBlob(
      blob,
      `${owner.lastname.toUpperCase()}_${owner.firstname}.svg`
    );
  };

  const handleSaveQrCode = () => {
    const canvas = document.getElementById("qrcode") as HTMLCanvasElement;

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${owner?.firstname}-${owner?.lastname}-JustOneCard.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleChangeCompany = async () => {
    // 1 - get the company id
    const id = prompt("Entrez l'id de la nouvelle entreprise");
    if (id === null || id === "") {
      alert("Annulation de la modification");
      return;
    }
    // 2 - Check if the company exists
    const newCompanySnapshot = await firebase
      .firestore()
      .collection("companies")
      .doc(id)
      .get();
    if (!newCompanySnapshot.exists) {
      alert("Cette entreprise n'existe pas");
      throw Error("Company does not exist");
    }
    const newCompanyData = newCompanySnapshot.data();
    if (newCompanyData === undefined) {
      alert("Cette entreprise a un pb de format");
      throw Error("Company data is undefined");
    }

    const company = {
      id: id,
      name: newCompanyData.name,
    };

    // 3 - update the owner
    const p1 = firebase.firestore().collection("owners").doc(ownerid).update({
      company,
    });

    // 4 - update the initial company
    const p2 =
      owner?.company &&
      owner?.company.id &&
      firebase
        .firestore()
        .collection("companies")
        .doc(owner.company.id)
        .update({
          employees: firebase.firestore.FieldValue.arrayRemove(ownerid),
        });
    // 5 - update the new company
    const p3 = firebase
      .firestore()
      .collection("companies")
      .doc(company.id)
      .update({
        employees: firebase.firestore.FieldValue.arrayUnion(ownerid),
      });

    await Promise.all([p1, p2, p3]);
    // 6 - Navigate to the new company
    history.push(`/admin/companies/${company.id}`);
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="">
          <div className="flex flex-row justify-between mx-2 mb-2">
            <BackButton />

            <button onClick={handleModify} className="btn btn-accent">
              Modifier
            </button>

            <button onClick={handleDelete} className="btn btn-error">
              Supprimer
            </button>
          </div>

          <div>
            <h1 className="text-3xl">
              {owner.firstname} {owner.lastname.toUpperCase()}
            </h1>
          </div>
          <div className="infos">
            <div>
              <div ref={mediumQrcodeRef}>
                <QRCodeSVG
                  value={`https://app.justonecard.fr/${ownerid}`}
                  size={128}
                  bgColor="transparent"
                  level="Q"
                />
              </div>
              <Button
                title="save as svg"
                onClick={() => {
                  if (mediumQrcodeRef) downloadSVGContent(mediumQrcodeRef);
                }}
              />
              <div>
                <QRCodeSVG
                  value={`https://app.justonecard.fr/${ownerid}`}
                  size={128}
                  bgColor="white"
                  level="H"
                  id="qrcode"
                  includeMargin
                  imageSettings={{
                    src: leaves,
                    excavate: true,
                    height: 30,
                    width: 30,
                  }}
                />
                <div className="userinfo-button-save-container">
                  <button
                    onClick={handleSaveQrCode}
                    className="userinfo-button-save"
                  >
                    Télécharger
                  </button>
                </div>
              </div>
            </div>

            <div className="infos__text">
            <div>
                CompanyId :{owner.company && owner.company.id}
              </div>
              <div>
                Société :{" "}
                <span
                  className=""
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    owner.company &&
                      history.push(`/admin/companies/${owner.company.id}`);
                  }}
                >
                  {owner.company && owner.company.name}
                </span>
              </div>
              <div>Utilisation : {owner.taps}</div>
              <button onClick={handleChangeCompany} className="btn btn-accent">
                Changer d'entreprise
              </button>
            </div>
          </div>
          <div>
            <span className="text-lg font-bold">
              ATTENTION : SI LE CLIENT EST ANCIEN AVANT DE RAJOUTER PLUSIEURS
              QRCODE IL FAUT REINSERER L'ANCIEN
            </span>
            {qrlink.length !== 0 ? (
              <ul>
                {qrlink.map((elmt) => {
                  return (
                    <li className="flex flex-row m-3">
                      <Button
                        onClick={() => handleDeleteQrcode(elmt)}
                        title="X"
                      />
                      <a
                        href={`https://app.justonecard.fr/${elmt}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://app.justonecard.fr/{elmt}
                      </a>
                    </li>
                  );
                })}
              </ul>
            ) : null}
            <div className="flex flex-row  items-center space-x-1">
              <span>https://app.justonecard.fr/ </span>
              <input
                className="bg-gray-300 "
                onChange={(e) => setTempQrlink(e.target.value)}
              ></input>

              <Button title="Link QR" onClick={() => addQrcode(tempQrlink)} />
            </div>
          </div>
          <div>
            <Button
              onClick={handleNavigationToAddCard}
              title="Ajouter une carte"
            />

            <div className="card-list">
              {cards.map((card) => {
                return (
                  <div
                    className=" cursor-pointer"
                    onClick={() => {
                      handleNavigateToCardInfo(card);
                    }}
                    key={card.id}
                  >
                    <BusinessCard
                      firstname={card.firstname}
                      lastname={card.lastname}
                      job={card.job}
                      company={card.company}
                      imgUrl={card.imageUrl}
                    />
                    {card.active ? (
                      <span className="text-center text-xl ">
                        Profil selectionné
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OwnerInfo;
