import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as FaIcons from "react-icons/fa";

type SidebarElement = {
  title: string;
  path: string;
  icon: JSX.Element;
  cname: string;
};
export const SidebarData: SidebarElement[] = [
  {
    title: "Accueil",
    path: "/admin",
    icon: <AiIcons.AiFillHome />,
    cname: "nav-text",
  },
  {
    title: "Utilisateurs",
    path: "/admin/owners",
    icon: <IoIcons.IoMdPeople />,
    cname: "nav-text",
  },
  {
    title: "Entreprises",
    path: "/admin/companies",
    icon: <FaIcons.FaRegBuilding />,
    cname: "nav-text",
  },

  {
    title: "QrCode",
    path: "/admin/qrcode",
    icon: <FaIcons.FaQrcode />,
    cname: "nav-text",
  },
  {
    title: "Account",
    path: "/admin/account",
    icon: <FaIcons.FaUser />,
    cname: "nav-text",
  }
];
