import React, {useState, useRef} from "react";
import {LoadScript, Autocomplete} from "@react-google-maps/api";

const library = ["places"];

function AutocompletePlaces({handleSubmit}) {
  const [autocomplete, setAutocomplete] = useState("");
  const inputRef = useRef(null);

  const onLoad = (autocomplete) => {
    setAutocomplete((c) => autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      handleSubmit(autocomplete.getPlace());
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_API_KEY_MAPS}
      libraries={library}
    >
      <h1>Autocompletion google</h1>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          ref={inputRef}
          autoCorrect="off"
          spellCheck="false"
          type="text"
          id="input-field-3"
          name="data_input_field_3"
          autoComplete="off"
          placeholder="Commence à taper"
          style={{
            boxSizing: `border-box`,

            border: `1px solid transparent`,
            width: `400px`,
            height: `40px`,
            padding: `0 12px`,
            margin: "10px",
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </Autocomplete>
    </LoadScript>
  );
}

export default React.memo(AutocompletePlaces);
