import * as Yup from "yup";

export const addressSchema = Yup.object().shape({
  label: Yup.string(),
  street: Yup.string(),
  city: Yup.string(),
  postalcode: Yup.string(),
  countryregion: Yup.string(),
});

export const cardValidationSchema = Yup.object().shape({
  firstname: Yup.string().required("Requis"),
  lastname: Yup.string().required("Requis"),
  company: Yup.string(),
  job: Yup.string(),
  mainwebsite: Yup.string().url("Veuillez entrez un url valide ( http(s):// )"),
  prophone: Yup.string().matches(
    RegExp("^[+][0-9]+$"),
    "'+' (ex: +33611111111) obligatoire"
  ),
  personalphone: Yup.string().matches(
    RegExp("^[+][0-9]+$"),
    "'+' obligatoire (ex: +33611111111)"
  ),
  officephone: Yup.string().matches(
    RegExp("^[+][0-9]+$"),
    "'+' obligatoire (ex: +33611111111)"
  ),
  proemail: Yup.string()
    .email("Veuillez saisir un email valide")
    .required("Requis"),
  personalemail: Yup.string().email("Veuillez saisir un email valide"),
  proaddress: addressSchema,
  otheraddress: addressSchema,

  secondwebsite: Yup.string().url(),
  facebook: Yup.string().matches(
    RegExp("^http(s*)://(www.)*facebook.com/[a-zA-Z0-9._àâçéèêëîïôûùüÿñæœ]+$"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  instagram: Yup.string().matches(
    RegExp("[a-zA-Z0-9._àâçéèêëîïôûùüÿñæœ]+$"),
    "Profil invalide (ex: '@' au debut)"
  ),
  linkedin: Yup.string().matches(
    RegExp(
      "^http(s*)://(www.)*linkedin.com/.*/[a-zA-Z0-9._àâçéèêëîïôûùüÿñæœ-]+$"
    ),
    "URL invalide (ex: '/' en fin, 'https://' manquant) \n format valide : https://www.linkedin.com/in/profil et https://www.linkedin.com/company/profil"
  ),
  youtube: Yup.string().matches(
    RegExp("^http(s*)://(www.)*youtube.com/c/[a-zA-Z0-9.àâçéèêëîïôûùüÿñæœ]+$"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  twitter: Yup.string().matches(
    RegExp("^http(s*)://(www.)*twitter.com/[a-zA-Z0-9._-àâçéèêëîïôûùüÿñæœ]+$"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  tiktok: Yup.string().matches(
    RegExp("^http(s*)://(www.)*tiktok.com/@[a-zA-Z0-9.-_àâçéèêëîïôûùüÿñæœ]+$"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  soundcloud: Yup.string().matches(
    RegExp(
      "^http(s*)://(www.)*soundcloud.com/[a-zA-Z0-9.-_àâçéèêëîïôûùüÿñæœ-]+$"
    ),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  doctolib: Yup.string().matches(
    RegExp("^http(s*)://(www.)*doctolib.fr/.*"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  houzz: Yup.string().matches(
    RegExp("^http(s*)://(www.)*houzz.fr/.*"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  ufdi: Yup.string().matches(
    RegExp("^http(s*)://(www.)*ufdi.fr/.*"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  cotemaison: Yup.string().matches(
    RegExp("^http(s*)://projets.cotemaison.fr/.*"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  skype: Yup.string().matches(
    RegExp("^http(s*)://(www.)*skype.com/.*"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  calendly: Yup.string().matches(
    RegExp("^http(s*)://(www.)*calendly.com/.*"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
  pinterest: Yup.string().matches(
    RegExp("^http(s*)://(www.)*pinterest.fr/.*"),
    "URL invalide (ex: '/' en fin, 'https://' manquant)"
  ),
});
