import CardInfo from "pages/admin/CardInfo";
import EditCard from "pages/admin/owners/EditCard";

import {Route, Switch, useRouteMatch} from "react-router-dom";

const CardInfoRoutes = () => {
  let {path} = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={path} exact>
          <CardInfo />
        </Route>
        <Route path={`${path}/edit`} exact>
          <EditCard />
        </Route>
      </Switch>
    </>
  );
};

export default CardInfoRoutes;
