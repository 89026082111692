import "./styles/main.scss";
import { Switch, Route, Redirect } from "react-router-dom";

import PrivateRoute from "routes/PrivateRoutes";
import { AuthRoutes } from "routes/AuthRoutes";
import { DashboardRoutes } from "routes/DashboardRoutes";
import Topbar from "components/Topbar";
import Sidebar from "components/Sidebar";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="app">
      <Sidebar />

      <div className="app__right-side">
        <Topbar />
        {/* </div> */}

        {/* <Switch> */}
        <div className="m-2">
          <Switch>
            <PrivateRoute path="/admin" component={DashboardRoutes} />
            <Route path="/auth" component={AuthRoutes} />
            <Redirect to="/admin" from="/" />
          </Switch>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default App;
