import firebase from "firebase";
import { User } from "types";
import { deleteAccountWithUid } from "./functionsHelper";

export const getCompanyUsers = async (companyId: string) => {
  const snapshot = await firebase
    .firestore()
    .collection("users")
    .where("companyIds", "array-contains", companyId)
    .get();

  const users = snapshot.docs.map((doc) => {
    const data = doc.data() as User;
    const id = doc.id;
    return { id, ...data };
  });
  return users;
};

export const deleteAllUsersOfCompany = async (companyId: string) => {
  const companyUsers = await getCompanyUsers(companyId);

  companyUsers.forEach(async (user: User) => {
    user.id && (await deleteAccountWithUid({ uid: user.id }));
  });
};
