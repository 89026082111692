import { Area } from "react-easy-crop/types";

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export default async function getCroppedImg(imageSrc: string, pixelCrop: Area) {
  const image = (await createImage(imageSrc)) as CanvasImageSource;

  // Blob URL HQ
  const canvas = document.createElement("canvas");
  let newWidth;
  pixelCrop.width >= 720 ? (newWidth = 720) : (newWidth = pixelCrop.width);
  let newHeight = newWidth * (4 / 3);
  canvas.width = newWidth;
  canvas.height = newHeight;
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    newWidth,
    newHeight
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  const blobUrl: string = await new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });

  // BASE 64 FOR VCF
  const canvas2 = document.createElement("canvas");
  let newWidth2;
  pixelCrop.width >= 240 ? (newWidth2 = 240) : (newWidth2 = pixelCrop.width);
  let newHeight2 = newWidth2 * (4 / 3);
  canvas2.width = newWidth2;
  canvas2.height = newHeight2;
  const ctx2 = canvas2.getContext("2d") as CanvasRenderingContext2D;

  ctx2.fillStyle = "#ffffff";
  ctx2.fillRect(0, 0, canvas2.width, canvas2.height);
  ctx2.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    newWidth2,
    newHeight2
  );

  // As Base64 string
  let base64 = canvas2.toDataURL("image/jpeg");
  // removing type for vcf
  base64 = base64.split(",")[1];

  return { blobUrl, base64 };
}
