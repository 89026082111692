import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import firebase from "firebase";
import Loading from "components/Loading";
import { MyTextInput } from "components/forms/FormComponents";
import { useHistory, useParams } from "react-router-dom";
import { removeAccents, removeSpace } from "helpers/format-string";
import { Company, Owner } from "types";
import { emptyOwner } from "helpers/fakeData";
import BackButton from "components/BackButton";
interface IParams {
  ownerid: string;
  companyid?: string;
}
const OwnerForm = ({ companyMode = false }) => {
  const [initialValues, setInitialValues] = useState<Owner>(emptyOwner);
  const history = useHistory();
  const params = useParams<IParams>();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  var temp = history.location.pathname.split("/");
  const editing = !(temp[temp.length - 1] === "add");

  useEffect(() => {
    (async () => {
      const snapshot = await firebase.firestore().collection("companies").get();
      if (snapshot.empty) {
        console.log("No matching documents.");
        return;
      }
      snapshot.forEach((doc) => {
        const data = doc.data() as Company;
        const newCompany = { id: doc.id, ...data };
        setCompanies((arr) => [...arr, newCompany]);
      });
      if (editing) {
        const ownerSnapshot = await firebase
          .firestore()
          .collection("owners")
          .doc(params.ownerid)
          .get();
        if (ownerSnapshot.exists) {
          console.log("No matching documents.");

          return;
        }
        const os = ownerSnapshot.data() as Owner;

        setInitialValues({
          id: ownerSnapshot.id,
          firstname: os.firstname,
          lastname: os.lastname,

          company: os.company,
        });
      }
    })();

    setIsLoading(false);
    //eslint-disable-next-line
  }, []);
  console.log(companyMode);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <BackButton />
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
              firstname: Yup.string().required("Required"),
              lastname: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const handleFormatUserId = (values: Owner) => {
                let { firstname, lastname } = values;
                firstname = firstname.toLowerCase();
                lastname = lastname.toLowerCase();
                let firstnameFormatted = removeSpace(removeAccents(firstname));
                let lastnameFormatted = removeSpace(removeAccents(lastname));
                let result;
                const totalLenght =
                  firstnameFormatted.length + lastnameFormatted.length;
                if (totalLenght > 12) {
                  lastnameFormatted = lastnameFormatted.slice(0, 1);
                }
                result =
                  firstnameFormatted +
                  lastnameFormatted +
                  "_" +
                  Math.random().toString(36).substr(2, 6).toUpperCase();

                return result;
              };
              const userid = handleFormatUserId(values);

              const { firstname, lastname, qrcode } = values;
              let company: Company;

              if (companyMode) {
                company = companies.filter(
                  (elmt) => elmt.id === params.companyid
                )[0];
              } else {
                company = companies.filter(
                  (elmt) => values.company && elmt.id === values.company.id
                )[0];
              }
              if (editing) {
                await firebase
                  .firestore()
                  .collection("owners")
                  .doc(userid)
                  .update({
                    firstname,
                    lastname,

                    company: { id: company.id, name: company.name },
                  });
              } else {
                await firebase
                  .firestore()
                  .collection("owners")
                  .doc(userid)
                  .set({
                    firstname,
                    lastname,

                    qrcode: qrcode !== "" ? [qrcode] : [],
                    company: { id: company.id, name: company.name },
                  });
                qrcode !== "" &&
                  typeof qrcode === "string" &&
                  (await firebase
                    .firestore()
                    .collection("qrcodes")
                    .doc(qrcode)
                    .set({ ownerid: userid }));
                await firebase
                  .firestore()
                  .collection("companies")
                  .doc(company.id)
                  .update({
                    employees: firebase.firestore.FieldValue.arrayUnion(userid),
                  });
              }

              setSubmitting(false);
              if (companyMode) {
                history.replace(`/admin/companies/${params.companyid}`);
                return;
              }
              if (editing) {
                history.replace(`/admin/owners/${params.ownerid}`);
              } else {
                history.replace("/admin/owners");
              }
            }}
          >
            <Form className="grid grid-cols-1 space-y-2">
              <MyTextInput
                label="Prénom"
                name="firstname"
                type="text"
                placeholder="Jane"
                disabled={editing}
              />

              <MyTextInput
                label="Nom"
                name="lastname"
                type="text"
                placeholder="Doe"
                disabled={editing}
              />

              {!editing && (
                <MyTextInput label="Qrcode" name="qrcode" type="text" />
              )}

              <button type="submit">{editing ? "Modifier" : "Créer"}</button>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};

export default OwnerForm;
