import { skyGrey } from "components/palette";
import {
  Address,
  BackgroundColor,
  Card,
  Colors,
  ColorSet,
  Company,
  Owner,
  SocialNetwork,
} from "types";

export const fakeSocialNetwork: SocialNetwork = {
  active: true,
  url: "",
};

export const fakeSocialNetworkFalse: SocialNetwork = {
  active: true,
  url: "",
};

export const fakeAddress: Address = {
  label: "",
  street: "",
  city: "",
  postalcode: "",
  countryregion: "",
};

export const fakeOwner: Owner = {
  firstname: "loading",
  lastname: "loading",
};

export const fakeColors: Colors = {
  background: "",
  fontcolor: "",
  subfontcolor: "",
  iconcolor: "",
};

export const fakeBackgroundColor: BackgroundColor = {
  behind: "",
  front: "",
};

export const fakeColorSet: ColorSet = {
  name: "",
  brandname: fakeColors,
  createcardbutton: fakeColors,
  businesscard: fakeColors,
  socialbutton: fakeColors,
  addcontact: fakeColors,
  infospanel: fakeColors,
  background: fakeBackgroundColor,
};

export const emptyOwner: Owner = {
  firstname: "",
  lastname: "",
  qrcode: "",
};

export const emptyCard: Card = {
  ...emptyOwner,

  templateid: "skyGrey",
  iscv: false,
  imageUrl: "",
  vcfUrl: "",
  active: false,
  imgType: "jpg",
  company: "",
  job: "",
  website: fakeSocialNetworkFalse,
  facebook: "",
  instagram: "",
  linkedin: "",
  youtube: "",
  twitter: "",
  tiktok: "",
  soundcloud: "",
  doctolib: "",
  houzz: "",
  ufdi: "",
  cotemaison: "",
  skype: "",
  calendly: "",
  pinterest: "",
  colors: skyGrey,
  prophone: "",
  personalphone: "",
  officephone: "",
  proemail: "",
  personalemail: "",
  proaddress: fakeAddress,
  otheraddress: fakeAddress,
  mainwebsite: "",
  secondwebsite: "",
  owner: fakeOwner,
};

export const emptyCompany: Company = {
  name: "",
  address: {
    label: "",
    street: "",
    city: "",
    postalcode: "",
    countryregion: "",
  },
  employees: [],
  phonenumber: "",
  email: "",
};
