import { useEffect, useMemo, useState } from "react";
import firebase from "firebase";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading";
import BackButton from "components/BackButton";
import Button from "components/Button";
import CustomTable from "components/UserTable";
import "./CompanyInfo.css";
import { Company, Owner, User } from "types";
import {
  createAdmin,
  deleteAccountWithUid,
  deleteOwner,
  randomString,
} from "helpers/functionsHelper";
import {
  deleteAllUsersOfCompany,
  getCompanyUsers,
} from "helpers/firestoreHelper";
import toast from "react-hot-toast";
interface IParams {
  companyid: string;
}
function CompanyInfo() {
  const [company, setCompany] = useState<Company>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [owners, setOwners] = useState<Owner[]>([]);
  const { companyid } = useParams<IParams>();
  const [hasCompany, setHasCompany] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    const getDataFromDB = async () => {
      const snapshot = await firebase
        .firestore()
        .collection("companies")
        .doc(companyid)
        .get();

      if (!snapshot.exists) {
        console.log("no matching document");
        return;
      }
      const data = snapshot.data() as Company;
      const { email, name, phonenumber, employees } = data;
      const companyUsers = await getCompanyUsers(companyid);
      setHasCompany(companyUsers.some((user) => user.role.name === "admin"));
      setCompany({
        address: data.address,
        email,
        name,
        phonenumber,
        employees,

        jocNetworkCode: data?.jocNetworkCode || "",
      });
      const snapshotEmployees = await firebase
        .firestore()
        .collection("owners")
        .where("company.id", "==", companyid)
        .get();
      if (snapshotEmployees.empty) {
        console.log("no matching elements");
        setIsLoading(false);
        return;
      }

      snapshotEmployees.forEach(async (doc) => {
        const newOwner = { id: doc.id, ...doc.data() } as Owner;
        setOwners((arr) => [...arr, newOwner]);
      });

      setIsLoading(false);
    };
    getDataFromDB();
    //eslint-disable-next-line
  }, []);

  const handleModify = () => {
    history.push(`/admin/companies/${companyid}/edit`);
  };

  const handleTeamsToSolo = async () => {
    const result = window.confirm(
      "Êtes vous sur de passer l'entreprise en solo ? Cette opération est irréversible"
    );
    if (!result) {
      return;
    }

    // 1 - Get company -> already in useState

    // 2 - Get all employees
    if (!company?.employees) {
      alert("Il n'y a pas d'employés dans cette entreprise");
      return;
    }
    const employees = company?.employees;
    // 3 - Loop over employees
    // 3.1 - create new owner with employee data
    const newOwners = await Promise.all(
      employees.map(async (employee) => {
        // 3.1.1 - Get employee
        const employeeSnapshot = await firebase
          .firestore()
          .collection("owners")
          .doc(employee)
          .get();
        const employeeData = employeeSnapshot.data() as Owner;
        let email = employeeSnapshot.data()?.email;
        if (!email) {
          email = prompt(
            `Email de ${employeeData?.firstname + " " + employeeData?.lastname}`
          );
        }
        if (!email) {
          email = prompt(
            `Email de ${
              employeeData?.firstname + " " + employeeData?.lastname
            }, dernier essai. Annuler pour ne pas effectuer l'opération`
          );
        }
        if (!email) {
          alert("Email non renseigné, opération annulée");
          throw new Error("Email non renseigné");
        }
        const newOwner = {
          ...employeeData,
          id: employeeSnapshot.id,
          email: email,
          company: {
            id: companyid,
            name: company.name,
          },
        };
        return newOwner;
      })
    );

    // 4 - Delete admins frùom company better to do it before,
    // because if there is one owner with the same email, the function will fail
    deleteAllUsersOfCompany(companyid);

    newOwners.forEach(async (newOwner, index) => {
      setProgress(Math.min((index / newOwners.length) * 100, 80));

      // 3.2 - Create company for the employee
      let newCompany = {
        id: newOwner.id,
        name: newOwner.firstname + " " + newOwner?.lastname,
        employees: [newOwner.id],
        phonenumber: "",
        email: newOwner.email,
      };

      await firebase
        .firestore()
        .collection("companies")
        .doc(newCompany.id)
        .set(newCompany);

      // 3.3 - Assign company to employee
      await firebase
        .firestore()
        .collection("owners")
        .doc(newOwner.id)
        .update({
          company: { id: newCompany.id, name: newCompany.name },
        });

      // 3.4 - Send admin
      await createAdmin({ companyid: newCompany.id, email: newCompany.email });
    });
    setProgress(85);

    setProgress(90);

    // 5 - Delete company

    await firebase.firestore().collection("companies").doc(companyid).delete();
    setProgress(99);
    // 6 - Redirect to companies
    history.push("/admin/companies");
  };
  const handleDelete = async () => {
    const result = window.confirm(
      "Supprimer l'entreprise ? \n Attention, les collaborateurs ne seront pas supprimés"
    );
    if (result) {
      setIsLoading(true);
      await deleteAllUsersOfCompany(companyid);
      await firebase
        .firestore()
        .collection("companies")
        .doc(companyid)
        .delete();

      setIsLoading(false);
      history.replace("/admin/companies");
    }
  };

  const handleNavigateToOwnerInfo = (ownerid: string) => {
    history.push(`/admin/owners/${ownerid}`);
  };

  const handleDeleteOwner = async (ownerid: string) => {
    const result = window.confirm(
      "Supprimer l'utilisateur ? \n Attention, la suppression est définitive ainsi que tous ses profils"
    );
    if (result) {
      setOwners((c) => c.filter((owner) => owner.id !== ownerid));

      await deleteOwner(ownerid);
    }
  };

  // Owners Table

  const columns = useMemo(
    () => [
      {
        Header: "Prénom", // optional string or valid JSX
        accessor: "firstname", // required(at least for beginners) => row["firstname"]
        maxWidth: 140,
        minWidth: 100,
      },
      {
        Header: "Nom",
        accessor: "lastname",
        // Use our custom `fuzzyText` filter on this column
        filter: "fuzzyText",
        maxWidth: 140,
        minWidth: 100,
      },

      {
        Header: "Url",
        accessor: "id",
        maxWidth: 150,
        minWidth: 100,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Supprimer ?",
        accessor: "delete",
        Cell: ({ cell }: any) => {
          return (
            <button
              onClick={() => handleDeleteOwner(cell.row.original.id)}
              style={{ backgroundColor: "red", padding: "0.5rem" }}
            >
              Supprimer
            </button>
          );
        },
        maxWidth: 150,
        minWidth: 100,
      },
    ],
    []
  );

  const handleNavigationToAdmins = () => {
    history.push(`${companyid}/admins`);
  };
  const handleNavigationToAddOwner = () => {
    history.push(`${companyid}/add`);
  };

  const [creatingJOCNetworkCode, setCreatingJOCNetworkCode] = useState(false);
  const handleCreateJOCNetworkCode = async () => {
    if (!company) {
      return;
    }
    setCreatingJOCNetworkCode(true);

    // GENERATE random string
    const code = randomString(6).toUpperCase();
    await firebase
      .firestore()
      .collection("companies")
      .doc(companyid)
      .update({ jocNetworkCode: code });
    setCompany({ ...company, jocNetworkCode: code });
    setCreatingJOCNetworkCode(false);
  };

  return (
    <>
      {isLoading || !company ? (
        <Loading />
      ) : (
        <div className=" ">
          <div className="flex flex-row justify-between mx-2 mb-2">
            <BackButton />

            <button onClick={handleModify} className="btn btn-accent">
              Modifier
            </button>

            <button onClick={handleDelete} className="btn btn-error">
              Supprimer
            </button>
          </div>

          <div className="infos">
            <div className="infos__text">
              <div>Société : {company.name}</div>
              <div>Email : {company.email}</div>
              <div>Tél : {company.phonenumber}</div>
              <div>CompanyId : {companyid}</div>
            </div>
          </div>
          <div className="flex flex-row  m-8 space-x-4">
            <Button
              onClick={handleNavigationToAddOwner}
              title="Ajouter un utilisateur"
            />
            <button
              onClick={handleNavigationToAdmins}
              className="btn btn-secondary w-32 "
            >
              Gestion des admins
            </button>

            {company.email && !hasCompany && (
              <button
                onClick={async () => {
                  try {
                    company.email &&
                      toast.promise(
                        createAdmin({ email: company.email, companyid }),
                        {
                          loading: "Envoi de l'invitation",
                          success: "Invitation envoyée",
                          error: "Erreur lors de l'envoi de l'invitation",
                        }
                      );
                    setHasCompany(true);
                  } catch (error) {
                    alert(error);
                  }
                }}
                className="btn btn-accent w-32"
              >
                Envoyer accès à company
              </button>
            )}
            {progress !== 0 && progress !== 100 ? (
              <div
                className="radial-progress"
                style={{ "--value": progress } as React.CSSProperties}
              >
                {progress}%
              </div>
            ) : (
              company.employees &&
              company.employees?.length > 1 && (
                <button
                  onClick={handleTeamsToSolo}
                  className="btn btn-error w-32 "
                >
                  Transformer en solo
                </button>
              )
            )}
          </div>
          <CustomTable
            columns={columns}
            data={owners}
            handleNavigation={handleNavigateToOwnerInfo}
          />
        </div>
      )}
    </>
  );
}

export default CompanyInfo;
