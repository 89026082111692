import AddCompany from "pages/admin/companies/AddCompany";
import EditCompany from "pages/admin/companies/EditCompany";
import CompanyInfo from "pages/admin/companies/CompanyInfo";
import Companies from "pages/admin/companies/Companies";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import CompanyAdmins from "pages/admin/companies/CompanyAdmins";
import CompanyAddOwner from "pages/admin/companies/CompanyAddOwner";

const CompanyRoutes = () => {
  let {path} = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={path} exact>
          <Companies />
        </Route>

        <Route path={`${path}/add`} exact>
          <AddCompany />
        </Route>
        <Route path={`${path}/:companyid/edit`}>
          <EditCompany />
        </Route>
        <Route path={`${path}/:companyid/add`}>
          <CompanyAddOwner />
        </Route>
        <Route path={`${path}/:companyid/admins`}>
          <CompanyAdmins />
        </Route>
        <Route path={`${path}/:companyid`}>
          <CompanyInfo />
        </Route>
      </Switch>
    </>
  );
};

export default CompanyRoutes;
