import { useState } from "react";
import { SidebarData } from "./SidebarData";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import "./Navbar.css";
import BrandBanner from "./BrandBanner";

function Sidebar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  return (
    <div className="app__left-side">
      <BrandBanner />
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="">
          <ul className="" onClick={showSidebar}>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cname}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </div>
  );
}

export default Sidebar;
