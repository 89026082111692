import OwnerForm from "components/owners/OwnerForm";

function AddOwner() {
  return (
    <>
      <h1 className="text-xl font-bold text-center underline">
        Ajouter un utilisateur
      </h1>
      <OwnerForm companyMode={false} />
    </>
  );
}

export default AddOwner;
