const removeAccents = (strAccents: string) => {
  const temp = strAccents.split("");
  var strAccentsOut: string[] | string = [];
  var strAccentsLen = temp.length;
  var accents =
    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  var accentsOut =
    "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  for (var y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(temp[y]) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(temp[y]), 1);
    } else {
      strAccentsOut[y] = temp[y];
    }
  }
  strAccentsOut = strAccentsOut.join("");
  return strAccentsOut;
};

const removeSpace = (str: string) => {
  return str.replace(/\s/g, "");
};

export {removeAccents, removeSpace};
