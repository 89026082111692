import firebase from "firebase";
import { Card, Owner } from "types";

export const randomString = (size: number) => {
  return Math.random()
    .toString(36)
    .substr(2, size - 1);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ParseMapsAPI = (result: any) => {
  // Parsing
  const address: any = {};
  result.address_components.forEach((elmt: any) => {
    switch (elmt.types[0]) {
      case "street_number":
        address.street_number = elmt.long_name;
        break;
      case "route":
        address.route = elmt.long_name;
        break;
      case "locality":
        address.city = elmt.long_name;
        break;
      case "country":
        address.country = elmt.long_name;
        break;
      case "postal_code":
        address.postalcode = elmt.long_name;
        break;
      case "premise":
        address.label = elmt.long_name;
        break;
      default:
        return;
    }
  });
  return address;
};

export const deleteQrcode = async (qr: string, ownerid: string) => {
  try {
    await firebase.firestore().collection("qrcodes").doc(qr).delete();
  } catch (e) {
    alert(e);
  }
  try {
    await firebase
      .firestore()
      .collection("owners")
      .doc(ownerid)
      .update({ qrcode: firebase.firestore.FieldValue.arrayRemove(qr) });
  } catch (e) {
    alert(e);
  }
};

export const linkQrcodeToOwner = async (qr: string, ownerid: string) => {
  if (qr === "") {
    alert("Vous ne pouvez pas lier un QRcode vide.");
    return;
  }
  const qrcodeRef = firebase.firestore().collection("qrcodes").doc(qr);
  const qrcodeSnapshot = await qrcodeRef.get();
  const qrcodeData = qrcodeSnapshot.data() as { ownerid: string };
  if (qrcodeSnapshot.exists && qrcodeData.ownerid !== "") {
    alert("Ce QRcode est déjà lié à un utilisateur.");
    throw new Error("QRcode already linked");
  }

  await firebase
    .firestore()
    .collection("owners")
    .doc(ownerid)
    .update({ qrcode: firebase.firestore.FieldValue.arrayUnion(qr) });

  await firebase
    .firestore()
    .collection("qrcodes")
    .doc(qr)
    .set({ ownerid: ownerid });
};

export const deleteOwner = async (ownerid: string) => {
  const ownerSnapshot = await firebase
    .firestore()
    .collection("owners")
    .doc(ownerid)
    .get();
  const { cards, company, qrcode } = ownerSnapshot.data() as Owner;

  cards &&
    cards.forEach(async (cardId) => {
      /* Delete storage */
      await deleteCard(cardId);
    });

  // Remove from his company

  company &&
    (await firebase
      .firestore()
      .collection("companies")
      .doc(company.id)
      .update({
        employees: firebase.firestore.FieldValue.arrayRemove(ownerid),
      }));

  // Remove his id from qrcodes
  let promises: Promise<void>[] = [];
  const qrcodeToOwner = qrcode as string[];

  qrcodeToOwner.forEach((qr) => {
    promises = [
      ...promises,
      firebase
        .firestore()
        .collection("qrcodes")
        .doc(qr)
        .update({ ownerid: "" }),
    ];
  });

  await Promise.all(promises);

  // delete owner
  await firebase.firestore().collection("owners").doc(ownerid).delete();
};

export const deleteCard = async (cardId: string) => {
  const cardSnapshot = await firebase
    .firestore()
    .collection("cards")
    .doc(cardId)
    .get();
  const card = cardSnapshot.data() as Card;

  const storageRef = firebase.storage().ref();

  // Delete image
  if (card.imgType) {
    const imageRef = storageRef.child(
      `${card.owner.id}/${card.id}.${card.imgType}`
    );
    await imageRef.delete();
  }

  // Delete vcf
  const vcfRef = storageRef.child(`${card.owner.id}/${card.id}.vcf`);
  await vcfRef.delete();

  /* Delete Card */
  await firebase.firestore().collection("cards").doc(card.id).delete();
};

interface CreateAdminProps {
  email: string;
  companyid: string;
}
export const createAdmin = async ({ email, companyid }: CreateAdminProps) => {
  if (email === "") {
    return;
  }
  const functions = firebase.app().functions("europe-west1");
  const createAdminCloudFunction = functions.httpsCallable("createAdmin");

  const result = await createAdminCloudFunction({
    userEmail: email,
    userCompanyid: companyid,
  });
  return result;
};

interface IDeleteAccountWithUid {
  uid: string;
}
export const deleteAccountWithUid = async ({ uid }: IDeleteAccountWithUid) => {
  if (uid === "") {
    return;
  }
  const functions = firebase.app().functions("europe-west1");
  const deleteAccountWithUidCloudFunction = functions.httpsCallable(
    "deleteAccountWithUid"
  );
  await deleteAccountWithUidCloudFunction({ uid });
};

interface IDeleteAccountWithEmail {
  email: string;
}
export const deleteAccountWithEmail = async ({
  email,
}: IDeleteAccountWithEmail) => {
  if (email === "") {
    return;
  }
  const functions = firebase.app().functions("europe-west1");
  const deleteAccountWithEmailCloudFunction = functions.httpsCallable(
    "deleteAccountWithEmail"
  );
  await deleteAccountWithEmailCloudFunction({ email });
};
