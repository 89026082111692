import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase";
import Loading from "components/Loading";
import BusinessCard from "components/cards/BusinessCard";
import Button from "components/Button";
import BackButton from "components/BackButton";
import {
  AllColors,
  blueClassic,
  coffee,
  ColorSetName,
  dark,
  sepia,
  skyGrey,
  strawberry,
} from "components/cards/palette";
import { Card, Owner } from "types";

interface IParams {
  cardid: string;
  ownerid: string;
}
function CardInfo() {
  const { cardid, ownerid } = useParams<IParams>();
  const [card, setCard] = useState<Card>();
  const [activated, setActivated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [colorChanging, setColorChanging] = useState(false);
  const colorSets: AllColors = {
    blueClassic: blueClassic,
    coffee: coffee,
    dark: dark,
    sepia: sepia,
    skyGrey: skyGrey,
    strawberry: strawberry,
  };
  const history = useHistory();

  const handleDelete = async (card: Card) => {
    if (card.active === true) {
      alert("Vous ne pouvez pas supprimer une carte active");
      return;
    }
    const result = window.confirm(
      "Supprimer la carte ? \n Attention, la suppression est définitive"
    );

    if (result) {
      setIsLoading(true);
      const snapshot = await firebase
        .firestore()
        .collection("cards")
        .doc(cardid)
        .get();
      const data = snapshot.data() as Card;
      const imgType = data.imgType;
      await firebase.firestore().collection("cards").doc(cardid).delete();
      await firebase
        .firestore()
        .collection("owners")
        .doc(ownerid)
        .update({
          cards: firebase.firestore.FieldValue.arrayRemove(cardid),
        });
      const imgRef = firebase
        .storage()
        .ref()
        .child(`${ownerid}/${cardid}.${imgType}`);
      await imgRef.delete();
      const vcfRef = firebase.storage().ref().child(`${ownerid}/${cardid}.vcf`);
      await vcfRef.delete();
      setIsLoading(false);

      history.replace(`/admin/owners/${ownerid}`);
    }
  };

  const handleModify = () => {
    history.push(`/admin/owners/${ownerid}/${cardid}/edit`);
  };

  useEffect(() => {
    const queryCardInfoFromDB = async () => {
      const snapshot = await firebase
        .firestore()
        .collection("cards")
        .doc(cardid)
        .get();
      if (!snapshot.exists) {
        console.log("no matched document");
        return;
      }
      const data = snapshot.data() as Card;

      setActivated(data.active);
      setCard({ id: snapshot.id, ...data });
      setIsLoading(false);
    };
    queryCardInfoFromDB();
    //eslint-disable-next-line
  }, []);

  const handleActivation = async () => {
    const snapshot = await firebase
      .firestore()
      .collection("owners")
      .doc(ownerid)
      .get();

    const data = snapshot.data() as Owner;

    if (data.active) {
      await firebase
        .firestore()
        .collection("cards")
        .doc(data.active)
        .update({ active: false });
    }

    await firebase
      .firestore()
      .collection("owners")
      .doc(ownerid)
      .update({ active: cardid });

    await firebase
      .firestore()
      .collection("cards")
      .doc(cardid)
      .update({ active: true });
    setActivated(true);
  };

  const changeColor = async (colorset: ColorSetName) => {
    if (!card) {
      return;
    }
    setColorChanging(true);
    const colors = colorSets[colorset];
    await firebase
      .firestore()
      .collection("cards")
      .doc(cardid)
      .update({ templateid: colorset });

    setCard({ ...card, colors: colors });

    setColorChanging(false);
  };
  return (
    <>
      {isLoading || card === undefined ? (
        <Loading />
      ) : (
        <div className="grid grid-cols-1 max-w-sm gap-2 ">
          <BackButton />

          {activated ? (
            <div className="bg-green-400 text-xl text-center"> Active </div>
          ) : (
            <Button
              title={activated ? "Active" : "Activer"}
              onClick={handleActivation}
              disabled={activated}
            />
          )}

          <button onClick={handleModify} className="btn btn-accent">
            Modifier
          </button>

          <button onClick={() => handleDelete(card)} className="btn btn-error">
            Supprimer
          </button>
          <BusinessCard
            firstname={card.firstname}
            lastname={card.lastname}
            job={card.job}
            company={card.company}
            imgUrl={card.imageUrl}
          />
          <div>
            <div>
              Palette selectionnée :{" "}
              {card?.colors?.name !== undefined
                ? card?.colors.name
                : "Bleu classique"}
            </div>
            <div>
              <button
                className="bg-transparent hover:bg-blue-500  text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => {
                  changeColor("blueClassic");
                }}
                disabled={colorChanging}
                title="Blue Classic"
              >
                Blue Classic
              </button>

              <button
                className="bg-transparent hover:bg-yellow-700  text-yellow-900 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => {
                  changeColor("coffee");
                }}
                disabled={colorChanging}
              >
                Coffee
              </button>
              <button
                className="bg-transparent hover:bg-gray-500  text-gray-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => {
                  changeColor("skyGrey");
                }}
                disabled={colorChanging}
              >
                Sky Grey
              </button>
              <button
                className="bg-transparent hover:bg-red-400  text-red-600 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => {
                  changeColor("strawberry");
                }}
                disabled={colorChanging}
              >
                Strawberry
              </button>
              <button
                className="bg-transparent hover:bg-yellow-500  text-yellow-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => {
                  changeColor("sepia");
                }}
                disabled={colorChanging}
              >
                Sepia
              </button>
              <button
                className="bg-transparent hover:bg-gray-700 text-gray-900 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => {
                  changeColor("dark");
                }}
                disabled={colorChanging}
              >
                Dark
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CardInfo;
