import { useEffect, useContext, useState } from "react";
import { AuthContext } from "AuthProvider";
import firebase from "firebase";
import { toast } from "react-hot-toast";
const db = firebase.firestore();

interface IUser {
  email: string;
  userId: string;
  companyIds: string[];
  role: {
    name: string;
    type?: string;
  };
}

const JUSTONECARD_COMPANY_ID =
  process.env.NODE_ENV === "development" ? "" : "kV781AxPfhqnTPORXp98";

const getUser = async (userId: string) => {
  const querySnapshot = await db
    .collection("users")
    .where("userId", "==", userId)
    .limit(1)
    .get();

  if (querySnapshot.empty) {
    return null;
  }

  return querySnapshot.docs[0].data() as IUser;
};

const createUser = async (userId: string, email: string) => {
  const newUser: IUser = {
    email,
    userId,
    companyIds: [""],
    role: {
      name: "admin",
      type: "JOC-admin",
    },
  };
  await db.collection("users").doc(userId).set(newUser);
  return newUser;
};

const changeUserCompanyId = async (userId: string, companyId: string) => {
  await db
    .collection("users")
    .doc(userId)
    .update({
      companyIds: [companyId],
      role: {
        type: "JOC-admin",
        name: "admin",
      },
    });
};

const ManageAccount = () => {
  const authContext = useContext(AuthContext);
  const [newCompanyId, setNewCompanyId] = useState<string>("");

  const onChangeCompanyId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCompanyId(e.target.value);
  };

  useEffect(() => {
    if (authContext?.user?.uid) {
      getUser(authContext.user.uid).then((user) => {
        if (!user && authContext?.user?.email) {
          createUser(authContext?.user.uid, authContext.user.email);
        }
      });
    }
  }, [authContext?.user]);

  const handleResetCompanyId = () => {
    if (!authContext?.user?.uid) return;
    if (newCompanyId === JUSTONECARD_COMPANY_ID) {
      toast("Votre companyId est déjà à sa valeur d'origine.", {
        style: { background: "#93E6FB", color: "#FFF" },
      });
      return;
    }
    changeUserCompanyId(authContext.user.uid, JUSTONECARD_COMPANY_ID)
      .then(() => {
        toast("Votre compte est désormais lié à l'entreprise JustOneCard.", {
          style: { background: "#34D399", color: "#FFF" },
        });
        setNewCompanyId("");
      })
      .catch(() => {
        toast("Une erreur est survenue lors de la remise à zéro de la valeur de companyId.", {
          style: { background: "#F87171", color: "#FFF" },
        });
      });
  };

  const handleChangeCompanyId = () => {
    if (authContext?.user?.uid && newCompanyId) {
      changeUserCompanyId(authContext.user.uid, newCompanyId)
        .then(() => {
          toast("CompanyId ajouté avec succès", {
            style: { background: "#34D399", color: "#FFF" },
          });
        })
        .catch(() => {
          toast("Erreur lors de l'ajout du companyId", {
            style: { background: "#F87171", color: "#FFF" },
          });
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="m-4 flex  min-h-[60%] min-w-[20rem] w-[50%] justify-center p-10 drop-shadow-md md:m-20 md:rounded-md">
        <div className="bg-red-2 flex w-full flex-col items-center justify-center">
          <div className="text-2xl font-semibold">Modifier le companyId</div>
          <div className="mt-2 text-center text-sm font-light text-gray-800">
            Quel CompanyId voulez vous attribuer à votre compte ?
          </div>
          <div className="flex flex-col mt-40 w-full">
            <input
              className="input input-bordered input- bg-gray-200"
              type="text"
              placeholder="CompanyId"
              value={newCompanyId}
              onChange={onChangeCompanyId}
            />
            <div className="flex items-center justify-center mt-4">
              <button
                className="btn btn-primary"
                onClick={handleChangeCompanyId}
              >
                Modifier
              </button>
              <button
                className="btn btn-error ml-2 text-white"
                onClick={handleResetCompanyId}
              >
                Retour à l'entreprise JustOneCard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAccount;
