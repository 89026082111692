import CompanyForm from "components/companies/CompanyForm";

function EditCompany() {
  return (
    <>
      <h1 className="text-xl font-bold text-center underline">
        Modification d'une enterprise
      </h1>
      <CompanyForm />
    </>
  );
}

export default EditCompany;
