import React from "react";
import firebase from "firebase";

function Home() {
  const sendEmailVerification = () => {
    const user = firebase.auth().currentUser;
    if (!user) {
      return;
    }
    user.sendEmailVerification().then(() => {
      // Email verification sent!
      // ...
    });
  };
  return (
    <div className="bg-gray-400 ">
      <h1>Dashboard</h1>
      <h2>Welcome to Dashboard!</h2>
      <p>{process.env.REACT_APP_AUTH_DOMAIN}</p>
      <button onClick={sendEmailVerification}>Send EMAIL</button>
      <br />
      <br />
    </div>
  );
}

export default Home;
