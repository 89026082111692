import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase";

import Loading from "components/Loading";
import Button from "components/Button";
import BackButton from "components/BackButton";
import { deleteAccountWithUid } from "helpers/functionsHelper";
import { getCompanyUsers } from "helpers/firestoreHelper";
import { User } from "types";

interface IParams {
  companyid: string;
}

interface IAdmin {
  id?: string;
  email: string;
}
interface CreateAdminProps {
  email: string;
  companyid: string;
}
function CompanyAdmins() {
  const functions = firebase.app().functions("europe-west1");

  const [isLoading, setIsLoading] = useState(true);
  const { companyid } = useParams<IParams>();
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [tempAdmin, setTempAdmin] = useState<string>("");
  useEffect(() => {
    const getAdminsFromDB = async () => {
      const snapshot = await firebase
        .firestore()
        .collection("users")
        .where("companyIds", "array-contains", companyid)
        .get();

      const users = await getCompanyUsers(companyid);

      users.forEach(async (user: User) => {
        if (user.role.name === "admin") {
          setAdmins((prev) => [...prev, { id: user.id, email: user.email }]);
        }
      });
    };
    getAdminsFromDB();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  const handleDeleteAdmins = async (admin: IAdmin) => {
    if (admin.id === undefined) {
      return;
    }

    await deleteAccountWithUid({ uid: admin.id });

    let newAdmins = admins.filter((elmt) => elmt.id !== admin.id);
    setAdmins(newAdmins);
  };

  const createAdmin = async ({ email, companyid }: CreateAdminProps) => {
    if (email === "") {
      return;
    }
    const createAdminCloudFunction = functions.httpsCallable("createAdmin");

    createAdminCloudFunction({
      userEmail: email,
      userCompanyid: companyid,
    });
    alert("admin ajouté");

    setAdmins((prev) => [
      ...prev,
      {
        email: email,
        id: "uid will be created in 30s, you can leave the page",
      },
    ]);
    setTempAdmin("");
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <BackButton />

          {admins.length !== 0 ? (
            <ul>
              {admins.map((elmt) => {
                return (
                  <li className="flex flex-row m-3" key={elmt.id}>
                    <Button
                      onClick={() => handleDeleteAdmins(elmt)}
                      title="X"
                    />

                    <span>{elmt.id}</span>
                    <span>{elmt.email}</span>
                  </li>
                );
              })}
            </ul>
          ) : null}
          <div className="flex flex-row  items-center space-x-1">
            <span>email: </span>
            <input
              className="bg-gray-300 "
              onChange={(e) => setTempAdmin(e.target.value)}
            ></input>

            <Button
              title="Ajouter un admin"
              onClick={() => createAdmin({ email: tempAdmin, companyid })}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CompanyAdmins;
