import React, { useEffect, useRef, useState } from "react";
import QRCode from "qrcode.react";
import Loading from "components/Loading";
import Button from "components/Button";
import JSZip from "jszip";

function QrGenerator() {
  const [isLoading, setIsLoading] = useState(true);
  const [randomid, setRandomid] = useState([]);
  const [number, setNumber] = useState(0);

  useEffect(() => {
    generateRandomID();

    setIsLoading(false);
  }, []);

  const generateRandomID = () => {
    const randomString = Math.random().toString(36).substr(2, 8).toUpperCase();
    const randomURL = "https://app.justonecard.fr/" + randomString;
    setRandomid((arr) => [...arr, randomURL]);
  };

  const generateQr = (input) => {
    setRandomid([]);
    for (let i = 0; i < input; i++) {
      generateRandomID();
    }
  };

  function downloadBlob(blob, filename) {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
  }

  const createSVG = (svgRef) => {
    const content = svgRef.firstChild.innerHTML;
    const fileName = svgRef.innerText;
    const contentWithSvg = `
    
<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" shape-rendering="crispEdges" height="113" width="113" viewBox="0 0 33 33" >
${content.split("<image")[0].slice(80, -1)}>
		
</svg>`;
    const blob = new Blob([contentWithSvg], { type: "image/svg+xml" });
    return { file: blob, fileName: `${fileName}.svg` };
  };
  const generateZip = (refs) => {
    var zip = new JSZip();
    const today = new Date();
    const todayParsed =
      today.getDate() +
      "-" +
      today.getMonth() +
      "-" +
      today.getFullYear() +
      "-" +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds();

    refs.current.forEach((elmt) => {
      elmt = createSVG(elmt);
      zip.file(elmt.fileName, elmt.file);
    });

    zip.generateAsync({ type: "blob" }).then(
      function (blob) {
        downloadBlob(blob, `qrcode-${todayParsed}.zip`);
      },
      function (err) {
        console.log(err);
      }
    );
  };

  const myRefs = useRef([]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <h1 className="text-2xl text-center m-2 font-bold">
            Générateur de QR (ATTENTION NE FONCTIONNE PAS SUR SAFARI)
          </h1>
          <div className="grid grid-rows-1 grid-cols-3 align-start m-3 border-2 border-solid p-2">
            <span>Rentrer le nombre de qrcode à générer: </span>
            <input
              className="border-2 border-solid"
              onChange={(e) => setNumber(e.target.value)}
            ></input>
            <Button onClick={() => generateQr(number)} title="Générer" />
          </div>
          <Button
            onClick={() => {
              // downloadAllSVG(myRefs);
              generateZip(myRefs);
            }}
            title="download all SVG"
          />
          <div className="grid grid-cols-3">
            {randomid.map((url, idx) => {
              return (
                <div ref={(el) => (myRefs.current[idx] = el)}>
                  <QRCode
                    key={url}
                    value={url}
                    renderAs="svg"
                    size={113}
                    bgColor="transparent"
                    level="Q"
                    imageSettings={{
                      excavate: true,
                      height: 30,
                      width: 30,
                    }}
                  />
                  <h3>{`${url.split(".fr/")[1]}`}</h3>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default QrGenerator;
