import AddCard from "pages/admin/owners/AddCard";
import AddOwner from "pages/admin/owners/AddOwner";
import EditOwner from "pages/admin/owners/EditOwner";
import OwnerInfo from "pages/admin/owners/OwnerInfo";
import Owners from "pages/admin/owners/Owners";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import CardInfoRoutes from "./CardInfoRoutes";

const OwnerRoutes = () => {
  let {path} = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={path} exact>
          <Owners />
        </Route>

        <Route path={`${path}/add`} exact>
          <AddOwner />
        </Route>
        <Route path={`${path}/:ownerid/add`}>
          <AddCard />
        </Route>
        <Route path={`${path}/:ownerid/edit`}>
          <EditOwner />
        </Route>
        <Route path={`${path}/:ownerid/:cardid`}>
          <CardInfoRoutes />
        </Route>
        <Route path={`${path}/:ownerid`}>
          <OwnerInfo />
        </Route>
      </Switch>
    </>
  );
};

export default OwnerRoutes;
