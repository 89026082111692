import OwnerForm from "components/owners/OwnerForm";

function CompanyAddOwner() {
  return (
    <>
      <h1 className="text-xl font-bold text-center underline">
        Ajouter un utilisateur
      </h1>
      <OwnerForm companyMode={true} />
    </>
  );
}

export default CompanyAddOwner;
