import "./BusinessCard.css";

interface BusinessCardProps {
  firstname: string;
  lastname: string;
  job: string;
  company: string;
  imgUrl: string;
}
function BusinessCard({
  firstname,
  lastname,
  job,
  company,
  imgUrl,
}: BusinessCardProps) {
  return (
    <div className="main-layout">
      <img src={imgUrl} className="left-layout" alt="logo"></img>
      <div className="right-layout-name">
        {firstname} {lastname}
      </div>
      <div className="right-layout-infos">
        {job} {company}
      </div>
    </div>
  );
}

export default BusinessCard;
