import React, {useEffect, useState} from "react";
import firebase from "firebase";
import {config} from "firebaseconfig";
export const app = firebase.initializeApp(config);
if (process.env.REACT_APP_PROJECT_ID === "justonecard-prod") {
  const appCheck = firebase.appCheck();
  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  appCheck.activate(
    "6LfLT0QcAAAAAPgprl6LSOhy-02RjJnEiV-Ny1D2",

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    true
  );
}

export interface AuthObject {
  user: firebase.User | null;
  authenticated: boolean;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
  loadingAuthState: boolean;
  ownerid?: String;
}

export interface updateUidResult {
  id: string;
}

export const AuthContext = React.createContext<AuthObject | null>(null);
export const AuthProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [loadingAuthState, setLoadingAuthState] = useState<boolean>(true);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoadingAuthState(false);
    });
  }, []);
  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
