import CompanyForm from "components/companies/CompanyForm";

function AddCompany() {
  return (
    <>
      <h1 className="text-xl font-bold text-center underline">
        Ajout d'une entreprise
      </h1>
      <CompanyForm />
    </>
  );
}

export default AddCompany;
