import {ColorSet} from "../../types";

export interface AllColors {
  blueClassic: ColorSet;
  coffee: ColorSet;
  dark: ColorSet;
  sepia: ColorSet;
  skyGrey: ColorSet;
  strawberry: ColorSet;
}

export type ColorSetName =
  | "blueClassic"
  | "coffee"
  | "dark"
  | "sepia"
  | "skyGrey"
  | "strawberry";

export const blueClassic: ColorSet = {
  name: "Blue classic",
  background: {behind: "#fff", front: "#dedede"},
  brandname: {background: "#000"},
  createcardbutton: {background: "#dedede", fontcolor: "#000"},
  businesscard: {
    background: "#12203c",
    fontcolor: "#fff",
    subfontcolor: "#b4b5b5",
  },
  socialbutton: {background: "#12203c", iconcolor: "#fff"},
  addcontact: {
    background: "linear-gradient(#f4b246 50%, #f49f11 50%)",
    fontcolor: "#fff",
  },

  infospanel: {
    background: "#fff",
    iconcolor: "#555658",
    fontcolor: "#f49f11",
  },
};

export const coffee: ColorSet = {
  name: "Coffee",
  background: {behind: "#fff", front: "#ede5d5"},
  brandname: {background: "#000"},
  createcardbutton: {
    background: "linear-gradient(#775f4c , #594225 )",
    fontcolor: "white",
  },
  businesscard: {
    background: "#39220f",
    fontcolor: "#fff",
    subfontcolor: "#eaeaea",
  },
  socialbutton: {background: "#39220f", iconcolor: "#fff"},
  addcontact: {
    background: "linear-gradient(#775f4c 50%, #594225 50%)",
    fontcolor: "#fff",
  },

  infospanel: {
    background: "#fff",
    iconcolor: "#555658",
    fontcolor: "#594225",
  },
};

export const skyGrey: ColorSet = {
  name: "Sky Grey",
  background: {behind: "#fff", front: "#B8BFBE "},
  brandname: {background: "#000"},
  createcardbutton: {
    background: "linear-gradient(#383D56 , #181D26  ) ",
    fontcolor: "white",
  },
  businesscard: {
    background: "#181D26 ",
    fontcolor: "#fff",
    subfontcolor: "#eaeaea",
  },
  socialbutton: {background: "#181D26 ", iconcolor: "#fff"},
  addcontact: {
    background: "linear-gradient(#383D56 , #181D26  )",
    fontcolor: "#FFF",
  },

  infospanel: {
    background: "#fff",
    iconcolor: "#555658",
    fontcolor: "#000",
  },
};

export const strawberry: ColorSet = {
  name: "Strawberry",
  background: {behind: "#fff", front: "#D98982"},
  brandname: {background: "#000"},
  createcardbutton: {
    background: "linear-gradient(#602121  , #400101  ) ",
    fontcolor: "white",
  },
  businesscard: {
    background: "#400101",
    fontcolor: "#fff",
    subfontcolor: "#eaeaea",
  },
  socialbutton: {background: "#400101", iconcolor: "#fff"},
  addcontact: {
    background: "linear-gradient(#602121  , #400101  )",
    fontcolor: "#fff",
  },

  infospanel: {
    background: "#fff",
    iconcolor: "#555658",
    fontcolor: "#B28676",
  },
};

export const sepia: ColorSet = {
  name: "Sepia",
  background: {behind: "#fff", front: "#BF926B "},
  brandname: {background: "#000"},
  createcardbutton: {
    background: "linear-gradient(38deg, #313131 , #000000 )",
    fontcolor: "#fff",
  },
  businesscard: {
    background: "#59331D ",
    fontcolor: "#fff",
    subfontcolor: "#eaeaea",
  },
  socialbutton: {background: "#000000", iconcolor: "#fff"},
  addcontact: {
    background: "linear-gradient(#313131  50%, #000000 50%)",
    fontcolor: "#fff",
  },

  infospanel: {
    background: "#fff",
    iconcolor: "#555658",
    fontcolor: "#BF926B",
  },
};

export const dark: ColorSet = {
  name: "Dark",
  background: {
    behind: "#fff",
    front: "linear-gradient(149deg, #797977 0%, #414140 23%, #000000 100%)",
  },
  brandname: {background: "#000"},
  createcardbutton: {
    background: "linear-gradient(38deg, #797977 0%, #3D3D3C 23%, #000000 100%)",
    fontcolor: "#fff",
  },
  businesscard: {
    background: "#000000",
    fontcolor: "#fff",
    subfontcolor: "#eaeaea",
  },
  socialbutton: {background: "#000000", iconcolor: "#fff"},
  addcontact: {
    background: "linear-gradient(#212121  50%, #000000 50%)",
    fontcolor: "#F0F5FA",
  },

  infospanel: {
    background: "linear-gradient(180deg, #F0F5FA 0%, #C0C3C4 100%)",
    iconcolor: "#555658",
    fontcolor: "#000000",
  },
};

export const colorSets: AllColors = {
  blueClassic: blueClassic,
  coffee: coffee,
  dark: dark,
  sepia: sepia,
  skyGrey: skyGrey,
  strawberry: strawberry,
};
