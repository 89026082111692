import { Route, useRouteMatch } from "react-router-dom";
import Home from "pages/admin/Home";

import OwnerRoutes from "./OwnerRoutes";
import CompanyRoutes from "./CompanyRoutes";
import QrGenerator from "pages/admin/QrGenerator";
import ManageAccount from "pages/admin/account/ManageAccount";

export const DashboardRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <Route path={path} exact>
        <Home />
      </Route>

      <Route path={`${path}/owners`}>
        <OwnerRoutes />
      </Route>

      <Route path={`${path}/companies`}>
        <CompanyRoutes />
      </Route>

      <Route exact path={`${path}/qrcode`}>
        <QrGenerator />
      </Route>
      <Route exact path={`${path}/account`}>
        <ManageAccount/>
      </Route>
    </div>
  );
};
