import { useHistory } from "react-router-dom";
import Button from "./Button";

function BackButton() {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  return <Button title="Retour" onClick={handleGoBack} />;
}

export default BackButton;
