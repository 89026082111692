import React from "react";
import firebase from "firebase";
import {useHistory} from "react-router-dom";

import Button from "./Button";

function Topbar() {
  const history = useHistory();

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        history.push("/auth/login");
      });
  };

  return (
    <>
      <div className="header">
        <div className=""></div>

        <Button title="disconnect" onClick={handleLogout} />
      </div>
    </>
  );
}

export default Topbar;
